<template lang="pug">
BlackFridayHome(v-if="isBlackFriday()")

.home(v-else)

  Headline
  HowItWorks
  Brands
  Why

  Offer(
    :subscriptionGiftPromotions="availableSubscriptionGiftPromotions"
  )

  Boxes

  CarePromotionPopup
</template>
<script setup lang="ts">
import type { WatchStopHandle } from "vue"
import type { SubscriptionGiftAvailabilityResponse } from "~/services/subscription/promotion/promotionService"
import PromotionService from "~/services/subscription/promotion/promotionService"

import Boxes from "./Sections/Boxes.vue"
import Brands from "./Sections/Brands.vue"
import Headline from "./Sections/Headline.vue"
import HowItWorks from "./Sections/HowItWorks.vue"
import Offer from "./Sections/Offer.vue"
import Why from "./Sections/Why.vue"
import CarePromotionPopup from "~/promotions/202502-care/PromotionPopup.vue"
import { useActiveSubscription } from "~/store/subscription/subscription"

useHead({
  title: "Box Magenta"
})

definePageMeta({
  alias: [ '/oferta' ]
})

const route = useRoute()
const availableSubscriptionGiftPromotions = ref<SubscriptionGiftAvailabilityResponse[]>([])
const subscription = useActiveSubscription()

const promotionIsAvailable = computed(() => {
  return availableSubscriptionGiftPromotions.value.find((promotion) =>
    promotion.ruleId == "2023-08-loccitane-au-bresil-01" || promotion.ruleId == "2023-08-loccitane-au-bresil-02"
  ) != undefined
})

const goToOffer = () => {
  setTimeout(() => {
    document.getElementById("oferta")?.scrollIntoView({
      behavior: "smooth",
    })
  })
}

var experimentsStopHandle: WatchStopHandle | null = null
onMounted(() => {
  if (route.path == "/oferta") {
    goToOffer()
  }

  PromotionService.listAvaiableSubscriptionGiftPromotions().then((response) => {
    availableSubscriptionGiftPromotions.value = response
  }).catch((error) => {
    captureException(new Error('Failed to list available subscription gift promotions', { cause: error }))
  })
})

onUnmounted(() => {
  experimentsStopHandle?.apply(this)
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.home
  margin: 24px 0 0
</style>
